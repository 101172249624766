<template>
  <ion-header>
    <ion-toolbar>
      <ion-title>{{ title }}</ion-title>
      <ion-buttons slot="end">
        <ion-button @click="closeModal()">Close</ion-button>
      </ion-buttons>
    </ion-toolbar>
    <slot name="search-bar"></slot>
  </ion-header>

  <ion-content class="ion-padding">
    <slot> {{ title }} </slot>
  </ion-content>

  <ion-footer v-if="footer">
    <slot name="footer"></slot>
  </ion-footer>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonFooter,
} from "@ionic/vue";

export default {
  props: {
    title: {
      type: String,
      default: "Modal",
    },
    footer: {
      require: false,
      default: false,
    },
  },
  emits: ["closeModal"],
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonFooter,
  },

  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>