<template>
  <form @submit.prevent="updateQuestion()" action="">
    <ion-row>
      <ion-col size="12">
        <ion-list>
          <ion-item v-if="validationError" lines="none">
            <ion-label className="ion-text-wrap">
              <ion-text color="danger">
                <p v-for="error of v$.$errors" :key="error.$uid">
                  <ion-icon
                    :ios="alertCircleOutline"
                    :md="alertCircleSharp"
                    size="small"
                    style="vertical-align: middle"
                  ></ion-icon
                  >{{ error.$property }}: {{ error.$message }}
                </p></ion-text
              >
            </ion-label>
          </ion-item>

          <editor
            class="boxsizingBorder"
            :api-key="tinyMceApiKey"
            :init="tinyMceInit"
            placeholder="Type Question..."
            v-model="question"
            :class="{ 'color-danger': v$.question.$error }"
          ></editor>

          <ion-item
            v-if="exam_subject"
            :class="{ 'color-danger': v$.chapter_id.$error }"
          >
            <ion-label position="floating">Chapter</ion-label>
            <ion-select v-if="chapters.length > 0" v-model="chapter_id">
              <ion-select-option
                v-for="item of chapters"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </ion-select-option>
            </ion-select>
            <ion-select v-else disabled>
              <ion-select-option> No chapters </ion-select-option>
            </ion-select>
          </ion-item>

          <ion-item :class="{ 'color-danger': v$.mark.$error }">
            <ion-label position="floating">Marks</ion-label>
            <ion-input type="number" step="0.1" v-model="mark"></ion-input>
          </ion-item>

          <ion-item
            :class="{ 'color-danger': v$.exam_question_type_id.$error }"
          >
            <ion-label position="floating">Question type</ion-label>
            <ion-select v-model="exam_question_type_id">
              <ion-select-option
                v-for="item of exam_question_types"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </ion-select-option>
            </ion-select>
          </ion-item>

          <span v-if="exam_question_type_id != descriptive_question_type_id">
            <ion-item :class="{ 'color-danger': v$.option_1.$error }">
              <ion-label position="floating">Option 1</ion-label>
              <ion-input type="text" v-model="option_1"></ion-input>
            </ion-item>

            <ion-item :class="{ 'color-danger': v$.option_2.$error }">
              <ion-label position="floating">Option 2</ion-label>
              <ion-input type="text" v-model="option_2"></ion-input>
            </ion-item>

            <ion-item :class="{ 'color-danger': v$.option_3.$error }">
              <ion-label position="floating">Option 3</ion-label>
              <ion-input type="text" v-model="option_3"></ion-input>
            </ion-item>

            <ion-item :class="{ 'color-danger': v$.option_4.$error }">
              <ion-label position="floating">Option 4</ion-label>
              <ion-input type="text" v-model="option_4"></ion-input>
            </ion-item>

            <ion-item :class="{ 'color-danger': v$.answer.$error }">
              <ion-label position="floating">Select Answer</ion-label>
              <ion-select v-model="answer">
                <ion-select-option value="1"> Option 1 </ion-select-option>
                <ion-select-option value="2"> Option 2 </ion-select-option>
                <ion-select-option value="3"> Option 3 </ion-select-option>
                <ion-select-option value="4"> Option 4 </ion-select-option>
              </ion-select>
            </ion-item>
          </span>
        </ion-list>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-button type="submit" expand="full" class="ion-margin-vertical">
          Save
        </ion-button>
      </ion-col>
    </ion-row>
  </form>
</template>

<script>
import {
  IonRow,
  IonCol,
  IonList,
  IonLabel,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonItem,
  IonIcon,
  IonButton,
  IonText,
  loadingController,
  alertController,
} from "@ionic/vue";

import { alertCircleOutline, alertCircleSharp } from "ionicons/icons";

import Editor from "@tinymce/tinymce-vue";

import {
  required,
  requiredIf,
  minValue,
  maxValue,
  helpers,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import moment from "moment";
import base_url from "../../../../apis/base_url";
import tinymcekey from "../../../../apis/tinymcekey";

export default {
  emits: ["closeModal"],
  props: ["resource"],
  components: {
    Editor,
    IonRow,
    IonCol,
    IonList,
    IonLabel,
    IonInput,
    IonSelect,
    IonSelectOption,
    IonItem,
    IonIcon,
    IonButton,
    IonText,
  },
  data() {
    return {
      name: null,
      validationError: null,
      exam_subject_id: this.resource.exam_subject_id,

      question: this.resource.description,
      exam_question_type_id: null,
      chapter_id: null,
      mark: this.resource.marks,

      option_1:
        this.resource.exam_question_type.name == "Objective"
          ? this.resource.exam_question_options[0].description
          : null,
      option_2:
        this.resource.exam_question_type.name == "Objective"
          ? this.resource.exam_question_options[1].description
          : null,
      option_3:
        this.resource.exam_question_type.name == "Objective"
          ? this.resource.exam_question_options[2].description
          : null,
      option_4:
        this.resource.exam_question_type.name == "Objective"
          ? this.resource.exam_question_options[3].description
          : null,

      answer:
        this.resource.exam_question_type.name == "Objective"
          ? (
              this.resource.exam_question_options.findIndex(
                (option) => option.is_correct == true
              ) + 1
            ).toString()
          : null,

      alertCircleOutline,
      alertCircleSharp,

      moment,

      xhrError: {
        header: null,
        description: null,
      },

      tinyMceApiKey: tinymcekey,

      tinyMceInit: {
        branding: false,
        height: 500,
        statusbar: true,
        menubar: false,
        mobile: {
          toolbar_mode: "floating",
        },
        plugins: [
          "code charmap table image imagetools lists fullscreen wordcount",
        ],
        toolbar_groups: {
          formatgroup: {
            icon: "format",
            tooltip: "Formatting",
            items:
              "bold italic underline strikethrough superscript subscript removeformat | forecolor backcolor",
          },
          paragraphgroup: {
            icon: "paragraph",
            tooltip: "Paragraph format",
            items:
              "h1 h2 h3 | alignleft aligncenter alignright alignjustify | indent outdent",
          },
          insertgroup: {
            icon: "plus",
            tooltip: "Insert",
            items: "link image table emoticons charmap hr | bullist numlist",
          },
        },
        toolbar:
          "code undo redo | formatgroup paragraphgroup insertgroup | image",

        images_upload_handler: this.images_uploader,
      },
    };
  },

  created() {
    this.fetchExamSubject();
    this.fetchQuestionTypes();
  },

  computed: {
    exam_subject() {
      return this.$store.getters["examSubject/specificResource"];
    },

    remaining_marks() {
      let full_mark = this.exam_subject.full_mark;
      let marks = this.exam_subject.exam_questions.map((question) =>
        question.id != this.resource.id ? parseFloat(question.marks) : 0
      );
      let total_marks = marks.reduce((a, b) => a + b, 0);
      let remaining_marks = full_mark - total_marks;
      return remaining_marks;
    },

    exam_question_types() {
      return this.$store.getters["attribute/examQuestionTypes"];
    },

    chapters() {
      if (!this.exam_subject) {
        return [];
      }
      return this.exam_subject.subject.chapters;
    },

    descriptive_question_type_id() {
      let question_types = this.exam_question_types;
      let descriptive_question_type = question_types.find(
        (question_type) => question_type.name == "Descriptive"
      );
      return descriptive_question_type != null
        ? descriptive_question_type.id
        : 1;
    },

    original_images_in_question() {
      let image_srcs = [];
      if (this.resource.description) {
        let images = this.resource.description.match(/<img.*?src="(.*?)"/g);
        if (images) {
          images.forEach((image) => {
            let src = image.match(/src="(.*?)"/)[1];
            let file_name = src.split("/").pop();
            image_srcs.push(file_name);
          });
        }
      }
      return image_srcs;
    },

    current_images_in_question() {
      let image_srcs = [];
      if (this.question) {
        let images = this.question.match(/<img.*?src="(.*?)"/g);
        if (images) {
          images.forEach((image) => {
            let src = image.match(/src="(.*?)"/)[1];
            let file_name = src.split("/").pop();
            image_srcs.push(file_name);
          });
        }
      }
      return image_srcs;
    },

    orphan_images() {
      let orphan_images = [];
      let original_images = this.original_images_in_question;
      let current_images = this.current_images_in_question;
      original_images.forEach((image) => {
        if (!current_images.includes(image)) {
          orphan_images.push(image);
        }
      });
      return orphan_images;
    },

    new_images() {
      let new_images = [];
      let current_images = this.current_images_in_question;
      let original_images = this.original_images_in_question;
      current_images.forEach((image) => {
        if (!original_images.includes(image)) {
          new_images.push(image);
        }
      });
      return new_images;
    },

    updated_question() {
      const temp_dir_name = "/storage/tiny_mce_uploaded_imgs";
      const updated_dir_name = "/storage/exam_question_images";

      console.log(temp_dir_name, updated_dir_name);
      let updated_question = this.question;
      updated_question = updated_question.replace(
        new RegExp(temp_dir_name, "g"),
        updated_dir_name
      );
      return updated_question;
    },
  },

  setup() {
    return { v$: useVuelidate() };
  },

  validations() {
    return {
      question: {
        required: helpers.withMessage("is required", required),
      },
      exam_question_type_id: {
        required: helpers.withMessage("is required", required),
      },

      chapter_id: {
        requiredIf: helpers.withMessage(
          "is required",
          requiredIf(() => {
            return this.chapters.length > 0;
          })
        ),
      },

      mark: {
        required: helpers.withMessage("is required", required),
        minValue: helpers.withMessage("Minimum 1 marks", minValue(1)),
        maxValue: helpers.withMessage(
          "Maximum " + this.remaining_marks + " marks",
          maxValue(this.remaining_marks)
        ),
      },

      option_1: {
        requiredIf: helpers.withMessage(
          "is required",
          requiredIf(() => {
            return (
              this.exam_question_type_id != this.descriptive_question_type_id
            );
          })
        ),
      },

      option_2: {
        requiredIf: helpers.withMessage(
          "is required",
          requiredIf(() => {
            return (
              this.exam_question_type_id != this.descriptive_question_type_id
            );
          })
        ),
      },

      option_3: {
        requiredIf: helpers.withMessage(
          "is required",
          requiredIf(() => {
            return (
              this.exam_question_type_id != this.descriptive_question_type_id
            );
          })
        ),
      },

      option_4: {
        requiredIf: helpers.withMessage(
          "is required",
          requiredIf(() => {
            return (
              this.exam_question_type_id != this.descriptive_question_type_id
            );
          })
        ),
      },

      answer: {
        requiredIf: helpers.withMessage(
          "is required",
          requiredIf(() => {
            return (
              this.exam_question_type_id != this.descriptive_question_type_id
            );
          })
        ),
      },
    };
  },

  methods: {
    setModalState(state) {
      this.modalState = state;
    },

    loader(message) {
      const loading = loadingController.create({
        message: message,
        backdropDismiss: false,
      });

      return loading;
    },

    alerter(header, message, buttons = ["close"]) {
      const alert = alertController.create({
        header: header,
        message: message,
        buttons: buttons,
      });

      return alert;
    },

    setErrorResponse(error) {
      if (error.response) {
        this.xhrError.header = error.response.data.header;
        this.xhrError.message = error.response.data.message;
      } else if (error.request) {
        this.xhrError.header = error.message || error.request;
        this.xhrError.message = "Please check your connection and try again";
      } else {
        this.xhrError.header = error.message;
        this.xhrError.message = "Something went wrong. Try again later";
      }
    },

    async updateQuestion() {
      this.v$.$touch();
      if (this.v$.$error) {
        this.validationError = true;
        return;
      }

      let data = {
        id: this.resource.id,
        exam_subject_id: this.exam_subject_id,
        chapter_id: this.chapter_id,
        exam_question_type_id: this.exam_question_type_id,
        question: this.updated_question,
        orphan_images: this.orphan_images,
        new_images: this.new_images,
        mark: this.mark,
        option_1: this.option_1,
        option_2: this.option_2,
        option_3: this.option_3,
        option_4: this.option_4,
        answer: this.answer,
      };

      const spinner = await this.loader("Saving...");
      spinner.present();

      try {
        await this.$store.dispatch("examQuestion/updateResource", data);
        spinner.dismiss();
        await this.$store.dispatch(
          "examSubject/specificResource",
          data.exam_subject_id
        );
        this.$emit("closeModal");
      } catch (error) {
        spinner.dismiss();
        this.setErrorResponse(error);
        const alertDialog = await this.alerter(
          this.xhrError.header,
          this.xhrError.message
        );
        alertDialog.present();
        this.validationError = null;
      }
    },

    async resizeImage(image) {
      var canvas = document.createElement("canvas");
      var ctx = canvas.getContext("2d");
      // resize image
      var MAX_WIDTH = 720;
      var MAX_HEIGHT = 720;
      var width = image.width;
      var height = image.height;
      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }
      canvas.width = width;
      canvas.height = height;

      //return resized image as base64
      ctx.drawImage(image, 0, 0, width, height);
      var dataurl = canvas.toDataURL("image/jpeg", 80);
      return dataurl;
    },

    async convertImageToBase64(blob) {
      const convertBlobToBase64 = (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onerror = reject;
          reader.onload = () => {
            resolve(reader.result);
          };
          reader.readAsDataURL(blob);
        });
      return convertBlobToBase64(blob);
    },

    async images_uploader(blobInfo, success, failure) {
      let base64 = await this.convertImageToBase64(blobInfo.blob());
      let img = await document.createElement("img");
      img.src = base64;
      await img.complete;
      let dataurl = await this.resizeImage(img);

      let response = await this.$store.dispatch("tinyMce/uploadImage", {
        base64: dataurl,
      });
      // console.log('here', response)

      if (response.status == 200) {
        success(base_url + response.data.image_url);
      } else {
        failure("Error uploading image");
      }
    },
    async fetchQuestionTypes() {
      try {
        await this.$store.dispatch("attribute/examQuestionTypes");
        this.exam_question_type_id = this.descriptive_question_type_id;
      } catch (error) {
        this.setErrorResponse(error);
        const alertDialog = await this.alerter(
          this.xhrError.header,
          this.xhrError.message
        );
        alertDialog.present();
      }
    },
    async fetchExamSubject() {
      const spinner = await this.loader("Loading...");
      spinner.present();

      try {
        await this.$store.dispatch(
          "examSubject/specificResource",
          this.exam_subject_id
        );
        this.exam_question_type_id = this.resource.exam_question_type_id;
        this.chapter_id = this.resource.chapter_id
          ? this.resource.chapter_id
          : null;
        spinner.dismiss();
      } catch (error) {
        spinner.dismiss();
        this.setErrorResponse(error);
        const alertDialog = await this.alerter(
          this.xhrError.header,
          this.xhrError.message
        );
        alertDialog.present();
      }
    },
  },
};
</script>

<style scoped>
.boxsizingBorder {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
</style>
