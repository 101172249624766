<template>
  <ion-list v-if="resources != null">
    <ion-card
      v-for="(item, index) in resources.data"
      :key="item.id"
      :ref="`items-${item.id}`"
    >
      <ion-item lines="none" class="ion-no-padding">
        <ion-card-header> Q{{ index + 1 }}. </ion-card-header>
        <span
          slot="end"
          style="margin-left: 1.5rem; font-size: 0.8rem; color: gray"
        >
          {{ item.exam_question_type.name }} ({{ item.marks }} marks)</span
        >
      </ion-item>
      <ion-card-content style="padding-top: 0">
        <ion-text>
          <span v-html="item.description"></span>
        </ion-text>
        <ion-text v-if="item.exam_question_type.name == 'Objective'">
          <p class="ion-padding-vertical">Options:</p>
          <p v-for="(item, index) in item.exam_question_options" :key="index">
            ({{ index + 1 }}) {{ item.description }}
            {{ item.is_correct == 1 ? "(Ans)" : "" }}
          </p>
        </ion-text>
      </ion-card-content>
      <ion-item>
        <ion-button
          @click="
            confirmDelete({
              id: item.id,
              exam_subject_id: item.exam_subject_id,
            })
          "
          fill="clear"
          slot="start"
          :disabled="exam_subject.exam_subject_state.name != 'Created'"
          :color="exam_subject.exam_subject_state.name != 'Created' ? 'medium' : 'primary'"
        >
          <ion-icon
            slot="icon-only"
            :ios="trashOutline"
            :md="trashSharp"
            size="small"
            style="color: #eb445a"
          ></ion-icon>
        </ion-button>
        <ion-button
          :disabled="exam_subject.exam_subject_state.name != 'Created'"
          :color="exam_subject.exam_subject_state.name != 'Created' ? 'medium' : 'primary'"
          slot="end"
          @click="setResourceToUpdate(item.id)"
          fill="clear"
        >
          <ion-icon
            slot="icon-only"
            :ios="createOutline"
            :md="createSharp"
            size="small"
            style="color: grey"
          ></ion-icon>
        </ion-button>
      </ion-item>
    </ion-card>
  </ion-list>
  <ion-list v-else>
    <ion-list-header>
      <p>No questions found</p>
    </ion-list-header>
  </ion-list>

  <ion-popover
    :is-open="popoverState"
    :event="popoverEvent"
    @didDismiss="setPopoverState(false)"
  >
    <base-popover
      @closePopover="setPopoverState(false)"
      :title="`${selectedResource.subject.standard.name} - ${selectedResource.subject.name}`"
    >
      <ion-item button @click="setPopoverState(false)">Set Questions</ion-item>
    </base-popover>
  </ion-popover>
  <ion-modal
    :is-open="updateModalState"
    @didDismiss="setUpdateModalState(false)"
  >
    <base-modal
      title="Update Exam Question"
      @closeModal="setUpdateModalState(false)"
    >
      <update-question
        @closeModal="setUpdateModalState(false)"
        :resource="resourceToUpdate"
      ></update-question>
    </base-modal>
  </ion-modal>
</template>
<script>
import {
  IonList,
  IonListHeader,
  IonItem,
  IonButton,
  IonIcon,
  alertController,
  IonPopover,
  IonModal,
  IonCard,
  // IonCardTitle,
  IonCardHeader,
  IonCardContent,
  IonText,
} from "@ionic/vue";

import {
  checkmarkCircleOutline,
  checkmarkCircleSharp,
  ellipsisHorizontalOutline,
  ellipsisHorizontalSharp,
  createOutline,
  createSharp,
  trashOutline,
  trashSharp,
} from "ionicons/icons";
import BasePopover from "../../../Base/BasePopover.vue";
import BaseModal from "../../../Base/BaseModal.vue";
import UpdateQuestion from "../ExamQuestion/UpdateQuestion.vue";
import moment from "moment";

export default {
  props: {
    resources: {
      type: Object,
      required: true,
    },
    exam_subject: {
      type: Object,
      required: true,
    },
  },

  components: {
    IonList,
    IonListHeader,
    IonItem,
    IonButton,
    IonIcon,
    IonPopover,
    BasePopover,
    BaseModal,
    IonModal,
    UpdateQuestion,
    IonCard,
    // IonCardTitle,
    IonCardHeader,
    IonCardContent,
    IonText,
  },
  data() {
    return {
      checkmarkCircleOutline,
      checkmarkCircleSharp,
      ellipsisHorizontalOutline,
      ellipsisHorizontalSharp,
      createOutline,
      createSharp,
      trashOutline,
      trashSharp,

      moment,

      popoverState: false,
      popoverEvent: null,
      selectedResource: null,

      updateModalState: false,

      resourceToUpdate: {
        id: null,
        name: null,
      },

      xhrError: {
        header: null,
        description: null,
      },
    };
  },

  computed: {
    exam_subject_state() {
      let exam_subject = this.exam_subject;
      return exam_subject.exam_subject_state.name;
    },
  },

  methods: {
    alerter(header, message, buttons = ["close"]) {
      const alert = alertController.create({
        header: header,
        message: message,
        buttons: buttons,
      });

      return alert;
    },

    setErrorResponse(error) {
      if (error.response) {
        this.xhrError.header = error.response.data.header;
        this.xhrError.message = error.response.data.message;
      } else if (error.request) {
        this.xhrError.header = error.message || error.request;
        this.xhrError.message = "Please check your connection and try again";
      } else {
        this.xhrError.header = error.message;
        this.xhrError.message = "Something went wrong. Try again later";
      }
    },

    async confirmDelete(resource) {
      const header = `Delete question from exam ?`;
      const message = "This action cannot be undone.";
      const buttons = [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: "Okay",
          handler: () => {
            this.delete(resource);
          },
        },
      ];
      const alertDialog = await this.alerter(header, message, buttons);

      alertDialog.present();
    },

    async delete(data) {
      try {
        await this.$store.dispatch("examQuestion/deleteResource", data);
         await this.$store.dispatch(
          "examSubject/specificResource",
          data.exam_subject_id
        );
      } catch (error) {
        this.setErrorResponse(error);
        const alertDialog = await this.alerter(
          this.xhrError.header,
          this.xhrError.message
        );
        alertDialog.present();
      }
    },

    openPopover(resource, ev) {
      this.popoverEvent = ev;
      this.setSelectedResource(resource);
      this.setPopoverState(true);
    },

    setPopoverState(state) {
      this.popoverState = state;
    },

    setSelectedResource(resource) {
      this.selectedResource = resource;
    },

    setUpdateModalState(state) {
      this.updateModalState = state;
    },

    setResourceToUpdate(id) {
      const resource = this.resources.data.find(
        (resource) => resource.id === id
      );
      this.resourceToUpdate = resource;
      this.setUpdateModalState(true);
    },
  },
};
</script>